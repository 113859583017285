body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$color-background: #fff;
$color-background-header: #a09c9c;
$color-background-footer: #d2d2d2;
$color-footer-border: #808080;
$color-button-topic: #f8f9e7;
$color-button-training: #f4f4f4;
$color-button-hovered: #61fff4;
$color-input: #f4f4f4;
$color-input-correct: #d3ffb5;
$color-font-base: #333;
$color-font-darkgrey: #434343;
$color-font-grey: #a0a0a0;
$color-font-white: #fff;
$color-border-grey: #efefef;
$color-border-input: #e4e3de;
$color-background-card: #ebecde;
