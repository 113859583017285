@import "../../styles/variables";

.pageNotFound {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    @include font(140px, 169px, 400);

    text-align: center;
    margin: 0;
    padding-top: 100px;

    @media (width <= 770px) {
      padding-top: 100px;
    }

    @media (width <= 550px) {
      font-size: 80px;
      line-height: 97px;
      padding-top: 100px;
    }
  }

  &__text {
    @include font(16px, 19px, 400);

    text-align: center;
    margin: 0;
    padding-top: 5px;

    &:first-of-type {
      padding-bottom: 50px;
    }

    @media (width <= 550px) {
      font-size: 12px;
      line-height: 15px;
    }
  }

  &__link {
    @include font(14px, 17px, 400);

    text-align: center;
    margin: 0;
    text-decoration: none;
    padding-top: 84px;

    &:hover {
      @include hovered;
    }

    @media (width <= 550px) {
        padding-top: 84px;
      }
    }

  &__arrowContainerTop {
    @include column;

    align-items: center;
  }

  &__arrow {
    width: 90px;
    height: 40px;

    &:hover {
      @include hovered;
    }
  
    @media (width <= 740px) {
      width: 50px;
      height: 25px;
    }
  }
}