@import "../../styles/variables";

.deckItem {
  background-color: $color-background;
  min-height: calc(100vh - 262px);
  position: relative;
  max-width: 1040px;
  width: 100%;

  @media (width <= 1040px) {
    max-width:  100vw;
  }

  &__section {
    @include column;

    justify-items: center;
    position: relative;
    padding: 30px 70px 20px;
    min-height: 300px;
    margin-right: auto;
    margin-left: auto;
    
    @media (width <= 760px) {
      padding: 40px 20px 20px;
      align-items: inherit;
      max-width: 97%;
    }
    
    @media (width <= 480px) {
      padding: 26px 5px 20px;
    }
  }

  &__words {
    @include row;

    padding: 60px 0 70px;
    justify-content: space-between;
    position: relative;
    max-width: 1040px;
    width: 100%;
    flex-wrap: wrap;
    align-self: center;
    
    @media (width <= 580px) {
      justify-content: center;
    }
  }

  &__buttonContainer {
    @include row;

    width: 100%;
    align-self: center;
    margin-bottom: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
    
    @media (width <= 990px) {
      justify-content: center;
    }
    
    @media (width <= 640px) {
      flex-direction: column;
      max-width: 280px;
      gap: 20px;
      align-items: center;
    }

    & button {
      width: 30%;

      @media (width <= 990px) {
        width: 240px;
      }
    }
  }
}