@import '../../styles/variables';

.trainScreenChoises {
  @include column;

  justify-items: center;
  position: relative;
  min-height: 400px;
  margin-right: auto;
  margin-left: auto;
  padding: 32px 0;
  min-width: 300px;
  max-width: 600px;

  @media (width <= 900px) {
    padding: 32px 0;
  }

  @media (width <= 386px) {
    padding: 20px 0;
    min-width: calc(100vw - 60px);
  }

  &__form {
    @include column;

    align-items: center;
    position: relative;
    gap: 28px;
    padding-bottom: 50px;

    @media (width <= 548px) {
      gap: 0;

      // переходим с гэпов на марджины ради браузеров на старых айфонах
      & * {
        margin-bottom: 20px;
      }
    }
  }

  &__letterButtonBlock {
    @include row;

    width: 100%;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (width <= 470px) {
      justify-content: space-evenly;
    }
  }

  &__answer {
    @include font(24px, 27px, 500);

    margin: 0;
    border: 1px solid $color-border-grey;
    padding: 8px;
    border-radius: 16px;
    text-align: center;
    min-width: 40%;

    @media (width <= 650px) {
      font-size: 22px;
      line-height: 22px;
      min-width: 40%;
    }
  }

  &__answer_hovered {
    &:hover {
      @include hovered;

      background-color: $color-button-hovered;
    }
  }

  &__disabledAnswer {
    @include font(24px, 27px, 500);

    opacity: 0.7;
    margin: 0;

    @media (width <= 650px) {
      font-size: 22px;
      line-height: 22px;
      min-width: 40%;
    }
  }

  &__buttonBlock {
    @include row;

    gap: 20px;
    max-width: 600px;
    width: 100%;

    @media (width <= 548px) {
      gap: 0;
    }

    @media (width <= 550px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 100%;
      min-width: 250px;
    }
  }
}

.wordCard {
  background-color: $color-background-card;
  border-radius: 20px;
  justify-content: center;
  border: 0.5px solid $color-border-grey;
  min-width: 160px;
  min-height: 80px;
  flex-flow: column wrap;
  padding: 20px;
  margin: 0 5px;
  display: flex;
  width: fit-content;
  overflow: hidden;
  gap: 6px;
  align-items: center;

  @media (width <= 790px) {
    padding: 20px 12px;
  }

  @media (width <= 548px) {
    min-width: 140px;
    padding: 20px 8px;
    margin: 0 0 20px;
  }

  @media (width <= 390px) {
    max-width: 120px;
    padding: 16px 6px;
  }

  &__word {
    font-family: $font-sans;
    line-height: 1.3;
    margin: 0;
    font-weight: 600;
    font-size: 26px;
    color: $color-font-darkgrey;
    text-align: center;

    @media (width <= 490px) {
      line-height: 20px;
      font-size: 18px;
    }

    @media (width <= 390px) {
      font-size: 16px;
    }
  }

  &__text {
    font-family: $font-sans;
    line-height: 20px;
    margin: 0;
    font-weight: 500;
    font-size: 15px;
    color: $color-font-grey;
    text-align: center;

    &:hover {
      @include hovered;
    }
  }

  &__translation {
    background-color: $color-background;
    line-height: 1.8;
    border-radius: 10px;
    text-align: center;
    box-sizing: border-box;
    padding: 0 3px;
    margin: 4px 0;
    width: 100%;
    border: 2px solid transparent;
    min-height: 30px;

    @media (width <= 390px) {
      font-size: 14px;
      line-height: 26px;
      margin: 6px 0;
    }
  }
}

.correct {
  background-color: $color-input-correct;
  border: 2px solid white;
  margin: 4px 0;
}

.incorrect {
  background-color: bisque;
  border: 2px solid white;
  margin: 4px 0;
}

.correctBtn {
  background-color: $color-input-correct;
  margin: 0;
}

.incorrectBtn {
  background-color: bisque;
  margin: 0;
}
