@import '../../styles/variables';

.footer {
  @include column;

  min-height: 162px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  background-color: $color-background-footer;
  border-top: 1px solid $color-footer-border;
  box-sizing: border-box;
  width: 100%;

  @media (width <= 600px) {
    padding: 30px 14px;
  }

  &__wrap {
    @include row;

    justify-content: space-between;
    max-width: 1040px;
    list-style: none;
    margin: 0;
    flex-wrap: wrap;
    gap: 200px;
    padding: 0;
    padding: 40px 0 24px;

    @media (width <= 1030px) {
      gap: 100px;
    }

    @media (width <= 780px) {
      gap: 50px;
    }

    @media (width <= 550px) {
      gap: 20px;
    }

    @media (width <= 460px) {
      flex-direction: column;
    }
  }

  &__linkText {
    cursor: pointer;
    text-decoration: none;
    list-style: none;
    color: $color-font-white;
    display: flex;
    padding-bottom: 20px;
    transition: transform 0.5s ease-in-out;
    align-items: center;

    @media (width <= 960px) {
      font-size: 22px;
      line-height: 26px;
    }

    @media (width <= 710px) {
      display: flex;
      flex-direction: column;
    }

    @media (width <= 510px) {
      display: flex;
      flex-direction: row;
    }
  }

  &__link {
    @include font(36px, 38px, 400);

    margin: 0;
    min-width: 220px;

    &:hover .footer__linkText {
      transform: scale(1.05);
      overflow: hidden;
      opacity: 0.5;
    }

    @media (width <= 960px) {
      line-height: 36px;
      font-size: 22px;
    }
  }

  &__socialIcon {
    width: 56px;
    height: 56px;
    padding-right: 20px;
    align-self: center;
    box-sizing: content-box;

    @media (width <= 710px) {
      padding-right: 0;
    }

    @media (width <= 510px) {
      padding-right: 10px;
    }
  }
}
