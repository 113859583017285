@import '../../styles/variables';

.backToTopic {
  @include column;

  justify-content: flex-end;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 200px;
  align-self: center;

  &__text {
    @include font(20px, 1.5, 400);

    margin: 0;
    color: $color-font-darkgrey;

    &:hover {
      @include hovered;
    }

    @media (width <= 740px) {
      font-size: 16px;
    }

    @media (width <= 640px) {
      font-size: 12px;
    }
  }

  &__arrow {
    width: 90px;
    height: 40px;

    &:hover {
      @include hovered;
    }

    @media (width <= 740px) {
      width: 50px;
      height: 25px;
    }
  }
}
