@import "../../styles/variables";

.numbersTraining {
  @include column;

  background-color: $color-background;
  min-height: calc(100vh - 262px);
  max-width: 1040px;
  align-items: center;
  
  @media (width <= 1080px) {
    padding: 20px;
  }

  @media (width <= 680px) {
    padding: 20px 8px;
  }

  &__section {
    @include column;

    position: relative;
    justify-items: center;
    padding: 30px 0 20px;
    max-width: 1040px;
    min-height: 300px;
    margin-right: auto;
    margin-left: auto;

    @media (width <= 760px) {
      padding: 40px 0 20px;
    }
  
    @media (width <= 480px) {
      padding: 26px 5px 20px;

    /* align-items: center; */
    }
  }
}

.cardsHolder {
  &__form {
    @include column;

    gap: 20px;
    padding-bottom: 50px;
    position: relative;
    max-width: 600px;
    align-self: center;

    @media (width <= 720px) {
      gap: 12px;
    }
  }

  &__input {
    display: block;
    height: 80px;
    border-radius: 20px;
    padding-left: 20px;
    background-color: $color-input;
    border: 0.5px solid $color-border-input;
    box-sizing: border-box;
    font-size: 26px;
    min-width: 600px;
    
    @media (width <= 680px) {
      max-width: 85vw;
      min-width: 85vw;
    }
  
    @media (width <= 570px) {
      padding-left: 15px;
    }

    @media (width <= 530px) {
      max-width: 85vw;
      min-width: 85vw;
      height: 60px;
      font-size: 22px;
    }
  }

  &__input_active {
    background-color: $color-input-correct;

    &:focus {
      background-color: $color-input-correct;
    }

    &:active {
      font-weight: bold;
      background-color: $color-input-correct;
    }
  }

  &__buttonBlock {
    @include row;

    justify-content: space-between;
    gap: 12px;

    @media (width <= 720px) {
      button {
        max-width: 210px;
        min-width: 210px;
      }
    }
    
    @media (width <= 630px) {
      gap: 30px;
    }
    
    @media (width <= 530px) {
      flex-direction: column;
      width: 85vw;
      gap: 30px;
      align-items: center;
    }

    @media (width <= 530px) {
      width: 100%;
      max-width: 100%;
      min-width: 250px;
    }
  }

  &__answer {
    @include column;

    height: 22px;
    max-width: 600px;
  }

  &__advice {
    height: 42px;
    max-width: 600px;

    & p {
      margin: 0;
      width: fit-content;

      @media (width <= 530px) {
        max-width: 85vw;
      }
    }
  }

  &__titleRow {
    @include row;

    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 16px;

    @media (width <= 530px) {
      max-width: 85vw;
    }
  }

  &__title {
    @include font(22px, 27px, 400);

    margin: 0;

    @media (width <= 650px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__hint {
    @include font(22px, 27px, 400);

    margin: 0;
    padding-bottom: 23px;
    color: $color-font-grey;

    &:hover {
      @include hovered;
    }
  }

  &__arrowContainer {
    @include column;

    place-content: center flex-end;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    width: 200px;
    align-self: center;
    margin-bottom: 20px;
  }

  &__repeatImg {
    width: 90px;
    height: 80px;

    &:hover {
      @include hovered;
    }
  }

  &__text {
    @media (width <= 770px) {
      font-size: 14px;
      line-height: 22px;
      max-width: 364px;
    }
    
    @media (width <= 650px) {
      padding-right: 0;
      font-size: 11px;
      line-height: 16px;
    }
  }

}