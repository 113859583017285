@import '../../styles/variables';
@import '../../vendor/Railroad/stylesheet.css';

.titleContainer {
  @include row;

  justify-content: space-between;
  max-width: 900px;
  position: sticky;
  top: 0;
  z-index: 3;
  background: $color-background;
  margin-bottom: 20px;
  border-bottom: 1px solid $color-border-input;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 10px;
  width: calc(100vw - 160px);

  @media (width <= 760px) {
    width: calc(100vw - 60px);
  }

  @media (width <= 580px) {
    width: calc(100vw - 70px);
    text-align: center;
    gap: 20px;
  }

  @media (width <= 420px) {
    text-align: center;
    gap: 12px;
    padding: 4px 8px 12px;
  }

  &__title {
    @include font(28px, 1.4, 400);

    margin: 0;
    color: $color-font-darkgrey;
    text-align: start;

    @media (width <= 740px) {
      font-size: 20px;
      font-weight: 600;
    }

    @media (width <= 420px) {
      font-size: 18px;
    }

    @media (width <= 340px) {
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__verbBlock {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__transcription {
    @include font(28px, 1.4, 400);

    margin: 0;
    text-align: start;
    color: $color-font-grey;
    padding-left: 12px;

    @media (width <= 740px) {
      font-size: 20px;
    }

    @media (width <= 420px) {
      text-align: center;
      font-size: 18px;
    }

    @media (width <= 340px) {
      font-size: 16px;
    }
  }

  &__arrowContainerTop {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    @include font(20px, 1.2, 400);

    margin: 0;
    color: $color-font-darkgrey;
    white-space: nowrap;

    &:hover {
      @include hovered;
    }

    @media (width <= 740px) {
      font-size: 16px;
    }

    @media (width <= 640px) {
      font-size: 12px;
    }
  }

  &__arrow {
    width: 90px;
    height: 40px;

    &:hover {
      @include hovered;
    }

    @media (width <= 740px) {
      width: 50px;
      height: 25px;
    }
  }
}
