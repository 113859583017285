@import '../../styles/variables';

.trainScreenChoises {
  @include column;

  justify-items: center;
  position: relative;
  max-width: 1140px;
  min-height: 400px;
  margin-right: auto;
  margin-left: auto;
  padding: 32px 0;
  min-width: 300px;

  @media (width <= 900px) {
    padding: 32px 0;
  }

  @media (width <= 386px) {
    padding: 20px 0;
    min-width: calc(100vw - 60px);
  }

  &__form {
    @include column;

    align-items: center;
    position: relative;
    gap: 28px;
    padding-bottom: 50px;
  }

  &__chooseButtonBlock {
    @include row;

    width: 100%;
    gap: 20px;
    justify-content: space-around;
    flex-wrap: wrap;

    @media (width <= 470px) {
      justify-content: space-evenly;
    }
  }

  &__answer {
    @include font(24px, 27px, 400);

    margin: 0;
    font-weight: 500;
    border: 1px solid $color-border-grey;
    padding: 8px;
    border-radius: 16px;
    min-width: 90px;
    text-align: center;

    &:hover {
      @include hovered;

      background-color: $color-button-hovered;
    }

    @media (width <= 650px) {
      font-size: 22px;
      line-height: 22px;
    }
  }

  &__noHover:hover {
    background-color: transparent;
    opacity: 1;
    cursor: auto;
  }

  &__buttonBlock {
    @include row;

    gap: 20px;

    @media (width <= 550px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 100%;
      min-width: 250px;
    }
  }
}

.wordCard {
  background-color: $color-background-card;
  border-radius: 20px;
  justify-content: center;
  border: 0.5px solid $color-border-grey;
  min-width: 160px;
  min-height: 80px;
  flex-flow: column wrap;
  padding: 20px;
  margin: 5px;
  display: flex;
  max-width: 240px;
  width: fit-content;
  overflow: hidden;
  gap: 6px;
  align-items: center;

  @media (width <= 790px) {
    max-width: 220px;
    padding: 20px 12px;
  }

  @media (width <= 490px) {
    min-width: 120px;
    padding: 20px 8px;
  }

  @media (width <= 390px) {
    max-width: 120px;
    padding: 16px 6px;
    margin: 3px;
  }

  &__word {
    font-family: $font-sans;
    line-height: 1.3;
    margin: 0;
    font-weight: 600;
    font-size: 26px;
    color: $color-font-darkgrey;
    text-align: center;

    @media (width <= 490px) {
      line-height: 20px;
      font-size: 18px;
    }

    @media (width <= 390px) {
      font-size: 16px;
    }
  }

  &__text {
    font-family: $font-sans;
    line-height: 20px;
    margin: 0;
    font-weight: 500;
    font-size: 15px;
    color: $color-font-grey;
    text-align: center;

    &:hover {
      @include hovered;
    }
  }

  &__translation {
    background-color: $color-background;
    line-height: 1.8;
    border-radius: 10px;
    text-align: center;
    box-sizing: border-box;
    padding: 0 3px;
    margin: 4px 0;
    width: 100%;
    border: 2px solid transparent;
    min-height: 30px;

    @media (width <= 390px) {
      font-size: 14px;
      line-height: 26px;
      margin: 6px 0;
    }
  }
}

.correct {
  background-color: $color-input-correct;
  border: 2px solid white;
}

.incorrect {
  background-color: bisque;
  border: 2px solid white;
}

.trainScreen {
  @include column;

  justify-items: center;
  position: relative;
  max-width: 1140px;
  min-height: 400px;
  margin-right: auto;
  margin-left: auto;
  padding: 32px 0;
  min-width: 300px;

  @media (width <= 900px) {
    padding: 32px 0;
  }

  @media (width <= 386px) {
    padding: 20px 0;
    min-width: calc(100vw - 60px);
  }

  &__form {
    @include column;

    position: relative;
    gap: 28px;
    padding-bottom: 50px;
  }

  &__buttonBlock {
    @include row;

    justify-content: center;

    button {
      width: 100%;
    }

    @media (width <= 530px) {
      flex-direction: column;
      width: 85vw;
      align-items: center;
    }

    @media (width <= 530px) {
      width: 100%;
      max-width: 100%;
      min-width: 250px;
    }
  }

  &__titleRow {
    @include row;

    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 16px;
    padding-bottom: 28px;
  }

  &__title {
    @include font(24px, 27px, 400);

    margin: 0;
    font-weight: 500;

    @media (width <= 650px) {
      font-size: 22px;
      line-height: 22px;
    }
  }

  &__hint {
    @include font(22px, 27px, 400);

    margin: 0;
    padding-bottom: 23px;
    color: $color-font-grey;

    &:hover {
      @include hovered;
    }
  }

  &__answer {
    @include column;

    height: 30px;
    max-width: 600px;
  }

  &__repeatImg {
    width: 90px;
    height: 80px;

    &:hover {
      @include hovered;
    }
  }

  &__decisionImg {
    width: 80px;
    height: 80px;
  }

  &__correct {
    button {
      background-color: #b1ff7c;
    }
  }

  &__incorrect {
    button {
      background-color: rgb(243 100 75);
    }
  }

  &__chooseButtonBlock {
    @include row;

    gap: 20px;
    justify-content: space-between;
  }

  &__decisionBtn {
    background-color: $color-background-card;
    border-radius: 20px;
    border: 1px solid $color-border-grey;
    box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 40%);
  }

  &__decisionBtn_hovered:hover {
    @include hovered;
  }
}
