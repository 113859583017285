@import '../../styles/variables';

.header {
  @include row;

  background: $color-background-header;
  color: $color-font-white;
  align-items: center;
  height: 110px;
  justify-content: center;
  width: 100%;

  &__content {
    @include row;

    width: 1040px;
    margin: 20px auto;
    align-items: center;

    @media (width <= 1180px) {
      width: calc(100vw - 70px);
      margin-left: 70px;
      margin-top: 0;
      margin-bottom: 0;
    }

    @media (width <= 770px) {
      width: calc(100vw - 102px);
      margin-left: 50px;
      margin-right: 50px;
    }

    @media (width <= 450px) {
      width: calc(100vw - 52px);
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  &__title {
    font-weight: 400;
    font-size: 28px;
    margin: 0;

    @media (width <= 770px) {
      font-size: 22px;
    }

    @media (width <= 450px) {
      font-size: 18px;
    }

    @media (width <= 380px) {
      font-size: 16px;
    }
  }

  &__icon {
    width: 60px;
    height: 60px;
    margin-left: 30px;

    @media (width <= 450px) {
      margin-left: 15px;
    }
  }

  &__iconHover {
    width: 60px;
    height: 60px;
    margin-left: 30px;

    &:hover {
      @include hovered;
    }

    @media (width <= 450px) {
      margin-left: 15px;
    }
  }
}
