@import "../../styles/variables";

.main {
  @include column;

  background-color: $color-background;
  min-height: calc(100vh - 262px);
}

.aboutMe {
  @include column;

  justify-items: center;
  position: relative;
  padding: 0 70px 20px;
  min-width: 1020px;
  min-height: 300px;
  margin-right: auto;
  margin-left: auto;

  @media (width <= 1160px) {
    min-width: 200px;
  }

  @media (width <= 450px) {
    padding: 0 20px 20px;
  }

  &__title {
    @include font(22px, 27px, 400);

    margin: 0;
    padding-bottom: 23px;

    @media (width <= 650px) {
      padding-bottom: 28px;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__statusText {
    @include font(28px, 30px, 400);

    margin: 0;
    padding-bottom: 26px;
    align-self: center;

    @media (width <= 1050px) {
      max-width: 420px;
      position: absolute;
      top: 80px;
      left: 70px;
    }

    @media (width <= 770px) {
      max-width: 220px;
      top: 60px;
    }

    @media (width <= 560px) {
      position: static;
      max-width: 400px;
      font-size: 24px;
    }

    @media (width <= 450px) {
      left: 50px;
    }
  }

  &__titleText {
    @include font(28px, 30px, 400);

    margin: 0;
    padding-bottom: 26px;
    align-self: center;
  }

  &__text {
    @include font(18px, 26px, 500);

    font-family: $font-sans;
    margin: 0;
    padding-bottom: 20px;

    @media (width <= 620px) {
      font-size: 18px;
      line-height: 24px;
      padding-right: 0;
    }

    @media (width <= 450px) {
      padding-right: 0;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__column {
    @include column;

    max-width: 770px;
    justify-content: space-between;

    @media (width <= 560px) {
      align-items: center;
    }
  }

  &__column1 {
    @media (width <= 1050px) {
      padding-top: 160px;
      padding-bottom: 0;
    }

    @media (width <= 560px) {
      padding-top: 30px;
    }
  }

  &__textBlock {
    @include row;

    padding-top: 66px;
    justify-content: space-between;
    gap: 20px;

    @media (width <= 1050px) {
      flex-direction: column-reverse;
      padding-top: 60px;
    }
  }

  &__photo {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    object-fit: cover;
    object-position: right bottom;

    @media (width <= 1050px) {
      position: absolute;
      top: 40px;
      right: 90px;
      width: 150px;
      height: 150px;
    }

    @media (width <= 560px) {
      position: static;
      right: 90px;
      width: 210px;
      height: 210px;
    }
  }

  &__link {
    @include font(18px, 26px, 500);

    text-decoration: none;
    color: $color-font-grey;
    padding-top: 30px;
    margin-bottom: 30px;

    &:hover {
      @include hovered;
    }

    @media (width <= 650px) {
      padding-top: 40px;
    }
  }
}

.decks {
  @include column;

  place-items: stretch center;
  position: relative;
  padding: 30px 70px 70px;
  color: $color-font-base;
  align-self: center;

  @media (width <= 770px) {
    padding: 20px 5px 0;
  }

  @media (width <= 400px) {
    padding: 20px 0 0;
  }

  &__mainContent {
    @include column;

    justify-items: center;
    position: relative;
    max-width: 1040px;
    flex-wrap: wrap;
    align-self: center;
  }

  &__contentWrap {
    @include row;

    justify-items: center;
    position: relative;
    max-width: 1040px;
    flex-wrap: wrap;
    padding-bottom: 40px;
    gap: 4px;
  }
}