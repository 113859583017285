@import '../../styles/variables';

.cardsHolder {
  @include column;

  justify-items: center;
  position: relative;
  padding: 32px 0 100px;
  max-width: 1140px;
  min-height: 400px;
  margin-right: auto;
  margin-left: auto;

  @media (width <= 900px) {
    padding: 32px 0;
  }

  @media (width <= 820px) {
    margin: 0;
  }

  @media (width <= 650px) {
    padding: 32px 14px;
  }

  @media (width <= 386px) {
    padding: 20px 10px;
  }

  &__form {
    @include column;

    position: relative;
    gap: 20px;
    padding-bottom: 50px;

    @media (width <= 720px) {
      gap: 12px;
    }
  }

  &__input {
    display: block;
    height: 80px;
    min-width: 600px;
    border-radius: 20px;
    padding-left: 20px;
    background-color: $color-input;
    border: 0.5px solid $color-border-input;
    box-sizing: border-box;
    font-size: 26px;

    @media (width <= 720px) {
      min-width: 478px;
    }

    @media (width <= 570px) {
      padding-left: 15px;
    }

    @media (width <= 540px) {
      max-width: 85vw;
      min-width: 85vw;
      height: 60px;
      font-size: 22px;
    }
  }

  &__input_active {
    background-color: $color-input-correct;

    &:focus {
      background-color: $color-input-correct;
    }

    &:active {
      font-weight: bold;
      background-color: $color-input-correct;
    }
  }

  &__buttonBlock {
    @include row;

    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;

    /* @media (max-width: 720px) {
      button {
        max-width: 210px;
        min-width: 210px;
      }
    } */

    @media (width <= 630px) {
      gap: 30px;
      padding-top: 12px;

      button {
        width: 50%;
      }
    }

    @media (width <= 530px) {
      flex-direction: column;
      width: 85vw;
      gap: 30px;
      padding-top: 0;
      align-items: center;
    }

    @media (width <= 530px) {
      width: 100%;
      max-width: 100%;
      min-width: 250px;
    }
  }

  &__titleRow {
    @include row;

    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__title {
    @include font(24px, 27px, 400);

    margin: 0;
    font-weight: 500;

    @media (width <= 650px) {
      font-size: 22px;
      line-height: 22px;
    }
  }

  &__hint {
    @include font(22px, 27px, 400);

    margin: 0;
    padding-bottom: 23px;
    color: $color-font-grey;

    &:hover {
      @include hovered;
    }
  }

  &__answer {
    @include column;

    height: 30px;
    max-width: 600px;
  }

  &__advice {
    height: 42px;
    max-width: 600px;

    & p {
      margin: 0;
    }
  }

  &__arrowContainer {
    @include column;

    place-content: center flex-end;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    width: 200px;
    align-self: center;
    margin-bottom: 20px;
  }

  &__repeatImg {
    width: 90px;
    height: 80px;

    &:hover {
      @include hovered;
    }
  }

  &__text {
    @media (width <= 770px) {
      font-size: 14px;
      line-height: 22px;
      max-width: 364px;
    }

    @media (width <= 650px) {
      padding-right: 0;
      font-size: 11px;
      line-height: 16px;
    }
  }
}
