@import '../../styles/variables';

@keyframes rotate {
  from {
    transform: rotateX(0deg);
  }

  to {
    transform: rotateX(180deg);
  }
}

.wordCard {
  background-color: $color-background-card;
  border-radius: 20px;
  justify-content: center;
  border: 0.5px solid $color-border-grey;
  min-width: 160px;
  min-height: 80px;
  flex-flow: column wrap;
  padding: 20px;
  margin: 5px;
  display: flex;
  max-width: 240px;
  width: fit-content;
  overflow: hidden;
  gap: 6px;
  align-items: center;

  @media (width <= 790px) {
    max-width: 220px;
    padding: 20px 12px;
  }

  @media (width <= 490px) {
    min-width: 120px;
    padding: 20px 8px;
  }

  @media (width <= 390px) {
    max-width: 120px;
    padding: 16px 6px;
    margin: 3px;
  }

  &__img {
    width: 60px;
    height: 20px;
    margin: 0 auto;

    &:hover {
      @include hovered;

      filter: drop-shadow(-10px 4px 4px rgba(0 0 0 / 90%));
    }
  }
}

.word {
  font-family: $font-sans;
  line-height: 1.3;
  margin: 0;
  font-weight: 600;
  font-size: 26px;
  color: $color-font-darkgrey;
  text-align: center;

  @media (width <= 490px) {
    line-height: 20px;
    font-size: 18px;
  }

  @media (width <= 390px) {
    font-size: 16px;
  }
}

.word1 {
  font-family: $font-sans;
  line-height: 1.3;
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  color: $color-font-darkgrey;
  text-align: center;
  width: min-content;

  @media (width <= 490px) {
    line-height: 20px;
    font-size: 18px;
  }

  @media (width <= 390px) {
    font-size: 16px;
  }
}

.text {
  font-family: $font-sans;
  line-height: 20px;
  margin: 0;
  font-weight: 500;
  font-size: 15px;
  color: $color-font-grey;
  text-align: center;

  &:hover {
    @include hovered;
  }
}

.translation {
  background-color: $color-background;
  line-height: 1.8;
  border-radius: 10px;
  text-align: center;
  box-sizing: border-box;
  padding: 0 3px;
  margin: 4px 0;
  width: 100%;

  @media (width <= 390px) {
    font-size: 14px;
    line-height: 26px;
    margin: 6px 0;
  }
}

.translation1 {
  background-color: $color-background;
  line-height: 1.8;
  border-radius: 10px;
  text-align: center;
  width: 100%;
}

.translation2 {
  background-color: $color-background;
  line-height: 1.8;
  border-radius: 10px;
  text-align: center;
  width: 100%;

  &:hover {
    @include hovered;
  }
}

.word__backside {
  font-family: $font-sans;
  line-height: 1.5;
  margin: 0;
  padding-bottom: 26px;
  padding-top: 16px;
  font-weight: 600;
  font-size: 38px;
  color: $color-font-darkgrey;
  text-align: center;
}
