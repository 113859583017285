@import '../../styles/variables';

.trainScreen {
  @include column;

  justify-items: center;
  position: relative;
  max-width: 1140px;
  min-height: 400px;
  margin-right: auto;
  margin-left: auto;
  padding: 32px 0;
  min-width: 300px;

  @media (width <= 900px) {
    padding: 32px 0;
  }

  @media (width <= 386px) {
    padding: 20px 0;
    min-width: calc(100vw - 60px);
  }

  &__form {
    @include column;

    position: relative;
    gap: 28px;
    padding-bottom: 50px;
  }

  &__buttonBlock {
    @include row;

    justify-content: center;

    button {
      width: 100%;
    }

    @media (width <= 530px) {
      flex-direction: column;
      width: 85vw;
      align-items: center;
    }

    @media (width <= 530px) {
      width: 100%;
      max-width: 100%;
      min-width: 250px;
    }
  }

  &__titleRow {
    @include row;

    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 16px;
    padding-bottom: 28px;
  }

  &__title {
    @include font(24px, 27px, 400);

    margin: 0;
    font-weight: 500;

    @media (width <= 650px) {
      font-size: 22px;
      line-height: 22px;
    }
  }

  &__hint {
    @include font(22px, 27px, 400);

    margin: 0;
    padding-bottom: 23px;
    color: $color-font-grey;

    &:hover {
      @include hovered;
    }
  }

  &__answer {
    @include column;

    height: 30px;
    max-width: 600px;
  }

  &__repeatImg {
    width: 90px;
    height: 80px;

    &:hover {
      @include hovered;
    }
  }

  &__decisionImg {
    width: 80px;
    height: 80px;
  }

  &__correct {
    button {
      background-color: #b1ff7c;
    }
  }

  &__incorrect {
    button {
      background-color: rgb(243 100 75);
    }
  }

  &__chooseButtonBlock {
    @include row;

    gap: 20px;
    justify-content: space-between;
  }

  &__decisionBtn {
    border-radius: 20px;
    border: 1px solid $color-border-grey;
    box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 40%);
  }

  &__decisionBtn_hovered:hover {
    @include hovered;
  }
}
