@import '../../styles/variables';

.searchForm {
  max-width: 1140px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  margin: 0;
  align-items: baseline;

  @media (width <= 770px) {
    grid-template-columns: auto;
    padding-bottom: 30px;
  }

  @media (width <= 500px) {
    grid-template-columns: auto;
    grid-template-rows: 72px 0;
    padding-bottom: 0;
  }

  &__inputContainer {
    grid-column: 1/1;
    grid-row: 1/1;
    flex-direction: row;
    position: relative;
    display: grid;
    grid-template-columns: 1 auto;
    max-width: 1077px;
    justify-content: center;
  }

  &__input {
    border-style: none;
    background: $color-input;
    border-radius: 9px;
    max-width: 900px;
    width: calc(100vw - 160px);
    height: 72px;
    z-index: 1;
    grid-column: 1/1;
    grid-row: 1/1;
    padding: 0 0 0 20px;
    box-sizing: border-box;

    &:focus-visible {
      outline: #e6e6e6 auto 0;
    }

    @media (width <= 760px) {
      width: calc(100vw - 60px);
    }

    @media (width <= 530px) {
      padding: 0 0 0 19px;
    }
  }

  &__img {
    height: 34px;
    z-index: 2;
    position: absolute;
    margin-left: 20px;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    place-self: center flex-end;
    margin-right: 20px;

    @media (width <= 530px) {
      display: none;
    }
  }
}
