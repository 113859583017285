@import "../../styles/variables";

.wordItem {
  background-color: $color-background;
  min-height: calc(100vh - 262px);
  
  &__content {
    @include column;

    justify-items: center;
    position: relative;
    padding: 30px 70px 20px;
    max-width: 1040px;
    min-height: 300px;
    margin-right: auto;
    margin-left: auto;
  
    @media (width <= 680px) {
      padding: 66px 20px 20px;
    }

    @media (width <= 480px) {
      padding: 66px 4px 20px;
    }
  }

  &__titleContainer1 {
    @include column;

    justify-content: space-between;
    position: relative;
    max-width: 1040px;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
  
  &__titleContainer {
    @include row;

    justify-content: space-between;
    position: relative;
    max-width: 1040px;
    flex-wrap: wrap;
    
    @media (width <= 780px) {
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
    }

    @media (width <= 680px) {
      align-items: center;
    }
  }

  &__title {
    @include font(28px, 30px, 400);

    margin: 0;
    padding-bottom: 26px;
    color: $color-font-darkgrey;

    @media (width <= 630px) {
      text-align: center;
    }
  }

  &__word {
    @include font(30px, 30px, 700);

    margin: 0;
    padding-bottom: 20px;
    color: $color-font-darkgrey;

    @media (width <= 490px) {
      line-height: 20px;
      font-size: 18px;
      padding-bottom: 10px;
    }
    
    @media (width <= 390px) {
      font-size: 16px;
    }
  }

  &__transcription {
    @include font(24px, 28px, 400);

    margin: 4px 0;
    padding: 4px 0;
    color: $color-font-grey;
    background-color: $color-background;
    border-radius: 10px;
    width: 100%;
    text-align: center;
  
    @media (width <= 390px) {
      font-size: 14px;
      line-height: 26px;
      margin: 6px 0;
    }
  }

  &__title1 {
    @include font(34px, 1, 700);

    margin: 0;
    padding-bottom: 28px;
    color: $color-font-darkgrey;
    padding-left: 20px;

    @media (width <= 630px) {
      padding-left: 0;
    }
  }

  &__text {
    @include font(20px, 30px, 400);

    margin: 0;
    color: $color-font-grey;
  }

  &__titleTextContainer {
    @include row;

    box-sizing: border-box;
  
    @media (width <= 630px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__formsContainer {
    @include row;

    justify-content: space-between;
    position: relative;
    max-width: 1040px;
    flex-wrap: wrap;
    padding-bottom: 30px;
    padding-top: 20px;
 
    @media (width <= 630px) {
      align-items: center;
      justify-content: center;
    }
  }

  &__formContainer {
    @include column;

    background-color: $color-background-card;
    border-radius: 20px;
    justify-content: center;
    border: 0.5px solid $color-border-grey;
    min-width: 160px;
    min-height: 80px;
    flex-wrap: wrap;
    padding: 20px;
    margin: 5px;
    max-width: 240px;
    width: fit-content;
    overflow: hidden;
    gap: 6px;
    align-items: center;
    text-align: center;
    
    @media (width <= 790px) {
      max-width: 220px;
      padding: 20px 12px;
    }
  
    @media (width <= 490px) {
      min-width: 120px;
      padding: 20px 8px;
    }
  
    @media (width <= 390px) {
      max-width: 120px;
      padding: 16px 6px;
      margin: 3px;
    }
  }
}